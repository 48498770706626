@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: local('Muli'), local('Muli-Regular'),
    url('./assets/fonts/Muli-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSansRegular';
  font-style: 'light';
  font-weight: 400;
  src: local('OpenSans'), local('OpenSans-Light'),
    url('./assets/fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSansBold';
  font-style: 'bold';
  font-weight: 600;
  src: local('OpenSans-Bold'),
    url('./assets/fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PlayfairDisplay-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('PlayfairDisplay'), local('PlayfairDisplay-Regular'),
    url(./assets/fonts/PlayfairDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'BebasNeueRegular';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/BebasNeue-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'BebasNeueBold';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/BebasNeue-Bold.ttf) format('truetype');
}
